<template>
  <div>
    <b-row no-gutters>
      <b-col cols="12">
        <b-card>
          <CustomFormProfileForm
            ref="custom-form-profile-form"
            v-model="customForm"
          />
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <CustomFormElementList v-model="formElements" @handleSubmit="handleSubmit"/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomFormProfileForm from "@/components/customForms/form/CustomFormProfileForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CustomFormElementList from "@/components/customForms/list/CustomFormElementList.vue";

export default {
  components: {
    CustomFormElementList,
    CustomFormProfileForm,
  },
  data() {
    return {
      customForm: {},
      formElements: [],
    };
  },
  computed: {
    ...mapGetters({
      form: "forms/getForm",
    }),
  },
  watch: {
    customForm: {
      handler(value) {
        if (value?.events?.length > 0) {
          const isRevision = value.events.some(
            ({ alias }) => alias === "review_asset"
            );
            
            const existField = this.formElements[0].elements.some(
              (element) => element.alias === "check" && element.default
            );
            
          if (isRevision && !existField) {
            this.formElements[0].elements.push({
              alias: "check",
              name: "Checkbox",
              id: 1,
              order: 6,
              config: {
                identifier: "apto",
                question: "Resultado",
                options: [
                  { label: "Apto", value: "Apto" },
                  { label: "No Apto", value: "No" },
                ],
              },
              default: true,
              observations: false,
            });
          }
          
          if (!isRevision && existField) {          
            this.deleteElementForRevision()
          }
        } else {
          this.deleteElementForRevision()
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions({
      edit: "forms/edit",
      getForm: "forms/getForm",
    }),
    deleteElementForRevision() {
      const element = this.formElements[0].elements.findIndex(
        (element) => element.default && element.alias === "check"
      );

      if(element > 0) {
        this.formElements[0].elements.splice(element, 1);
      }
    },
    async handleSubmit() {
      const profileFormValid = await this.$refs[
        "custom-form-profile-form"
      ].validate();
      if (!profileFormValid) {
        return;
      }

      if (this.formElements.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error formulario",
            text: "Debes añadir al menos un elemento de formulario",
            variant: "danger",
          },
        });

        return;
      }

      this.edit({ id: this.customForm.id, form: this.createFormData() });
    },
    createFormData() {
      const data = new FormData();
      this.customForm.events.forEach((element) => {
        data.append("events[]", element.id);
      });

      const structureFormElements = this.formElements.flatMap((formElement, sectionIndex) => {
        formElement.elements = formElement.elements.map((element) => {
          element.order = `${sectionIndex}-${element.order}`
          element.block_info = {
            id: sectionIndex,
            name: formElement.title,
          }
          return element
        })
        return formElement.elements
      });

      structureFormElements.forEach((element) => {
        data.append("formElements[]", JSON.stringify(element));
      })

      if (this.customForm.company) {
        data.append("company_id", this.customForm.company.id);
      }
      if (this.customForm.client) {
        data.append("client_id", this.customForm.client.id);
      }
      data.append("name", this.customForm.name);
      // corregir!!!!!!!!
      data.append("active", this.customForm.active || 0);
      data.append("title", this.customForm.title || '');
      data.append("description", this.customForm.description || '');

      return data;
    },
  },
  async mounted() {
    await this.getForm(this.$route.params.id);
    
    this.customForm = this.form;

    let formElementsStructure = []
    
    // se arama cada bloque
    this.form.formElements.forEach((element) => {
      formElementsStructure[element.block_info.id] = {}
      formElementsStructure[element.block_info.id].id = element.block_info.id
      formElementsStructure[element.block_info.id].title = element.block_info.name
      formElementsStructure[element.block_info.id].elements =  []
      formElementsStructure[element.block_info.id].edit = false
    })
    // se filtra cada elemento deacuerdo a su bloque
    this.formElements = formElementsStructure.map((section) => {
      section.elements = this.form.formElements.filter(
        (element) => element.block_info.id === section.id).map(
          // se le asigna la propiedad default
          (element) => {
            element.default = section.id === 0
            element.order = parseInt(element.order.split('-')[1])
            return element
            // se ordena por la propiedad order
          }).sort((a, b) => a.order - b.order)
      return section
    }).sort((a, b) => a.id - b.id)
  },
};
</script>
